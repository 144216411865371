<template>
  <div class="headerSection bg-white scrollBar" :class="{ shadowBox: shadow }">
    <div class="container">
      <div class="row align-items-center d-flex justify-content-between">
        <div class="col-lg-1 col-md-5 col-5">
          <div class="logo-box">
            <LocLink to="/">
              <img src="~/assets/img/logo.svg" alt="image" class="img-fluid" />
            </LocLink>
          </div>
        </div>

        <div class="col-lg-3 col-md-1 col-1" :class="[rtl ? 'me-auto' : 'ms-auto']">
          <div class="profileBox-login" v-if="!loginBox">
            <LayoutLoginBox v-if="login" />
            <LayoutProfileBox v-else />
          </div>
        </div>

        <div class="col-lg-2 col-md-5 col-5 d-flex">
          <ul class="social-links d-flex flex-row gap-3 align-items-center">
            <span>
              <img src="~/assets/img/or_sympol.svg" />
            </span>

            <li class="li">
              <a href="https://x.com/acceptixedtch?s=21&t=3hrgwimf5NKEcWLpuXSBPA" target="_blank">
                <img src="~/assets/img/icn_twitter_x_blue.svg" alt="Twitter" />
              </a>
            </li>
            <li class="li">
              <a href="https://www.facebook.com/AcceptixEdtch?mibextid=LQQJ4d" target="_blank">
                <img src="~/assets/img/icn_facebook_blue.svg" alt="Facebook" />
              </a>
            </li>
            <li class="li">
              <a href="https://www.tiktok.com/@acceptixedtch?_t=8m9ambQ9Sr7&_r=1" target="_blank">
                <img src="~/assets/img/icn_tiktok_blue.svg" alt="Tiktok" />
              </a>
            </li>
            <li class="li">
              <a href="https://www.instagram.com/acceptixedtch?igsh=djFtcjVranZ5aG9l" target="_blank">
                <img src="~/assets/img/icn_instagram_blue.svg" alt="Twitter" />
              </a>
            </li>

            <span>
              <img src="~/assets/img/or_sympol.svg" />
            </span>
          </ul>

          <LayoutLang />
          <LayoutSideMenu :login="login" :loginBox="loginBox" />
        </div>
      </div>
    </div>
  </div>

  <div class="headerSection bg-white pb-3 hide-on-sm">
    <div class="col-12 d-flex justify-content-center">
      <div class="nav-box">
        <ul class="list-unstyled">
          <li>
            <LocLink to="/why-acceptix">{{ $t('whystudentcenter') }}</LocLink>
          </li>
          <li>
            <a href="/#howWorkSection">{{ $t('howdowework') }}</a>
          </li>
          <li class="destinations">
            <LocLink to="/destinations">{{ $t('destinations') }}</LocLink>
            <div class="destinations-box">
              <Listbox v-model="selectedCountry" :options="destinations" optionLabel="name" to="'/'">
                <template #option="slotProps" @click="addCountrySearch(item.id, item.name)">
                  <LocLink :to="getDestinationLink(slotProps.option)">
                    <div class="d-flex gap-2 align-items-center">
                      <!-- <img :alt="slotProps.option.name + 'flag'" :src="slotProps.option.image"
                        :class="`flag flag-${slotProps.option.code} mr-2`" style="width: 18px" /> -->
                      <div v-html="slotProps.option.flag" class="phone-country-flag"></div>
                      {{ slotProps.option.name }}
                    </div>
                  </LocLink>
                </template>
              </Listbox>
            </div>
          </li>
          <li>
            <LocLink to="/search/university">{{ $t('findUniversities') }}</LocLink>
          </li>
          <li>
            <LocLink to="/search/institute">{{ $t('findEnglishSchools') }}</LocLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import destinationLink from '@/mixins/destinationLink';
export default {
  mixins: [destinationLink],
  created() {
    const userToken = useCookie('token');
    this.token = userToken.value;
  },

  beforeDestroy() {
    if (process.client) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },
  data: () => ({
    loginBox: true,
    login: true,
    logout: false,
    token: null,
    // scrollNav: false,
    shadow: false,
    hideNav: false,
    selectedCountry: [],
  }),
  computed: {
    rtl() {
      return this.$i18n.locale == 'ar';
    },

    destinations() {
      return this.$store.state.destinations.destinationsList;
    },
  },
  async mounted() {
    if (process.client) {
      window.addEventListener('scroll', this.handleScroll);
    }
    if (this.token) {
      this.login = false;
    }
    this.loginBox = false;
    await this.$nextTick();
  },
  methods: {
    addCountrySearch(id, name) {
      this.$router.push({
        path: this.localePath('/search/university'),
        query: { country_id: id, country_name: name },
      });
    },
    handleScroll() {
      if (process.client) {
        this.shadow = window.scrollY > 50;
      }
    },
  },
  watch: {
    '$store.state.profile.token': {
      immediate: true,
      handler(newToken) {
        this.login = !Boolean(newToken);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.headerSection {
  position: relative;
  z-index: 99;

  .hideNav {
    display: none;
  }

  .destinations {
    position: relative;

    .destinations-box {
      position: absolute;
      top: calc(100%);
      display: none;

      .flag {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    &:hover {
      .destinations-box {
        display: block;
      }
    }

    :deep(.p-listbox) {
      border-color: #f0f0f0;
      box-shadow: 0px 4px 8px 0px #0000001a;
      border-radius: 10px;
      min-width: 190px;
    }

    :deep(.p-listbox-list) {
      padding: 0 !important;
      width: 215px;
    }

    :deep(.p-listbox-item) {
      padding: 0 !important;
      margin: 10px;
      border-radius: 10px;
      color: $color-primary;
      position: relative;

      &:hover {
        background-color: #f0f9ff !important;
        cursor: pointer;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -5px;
        width: 100%;
        border-bottom: 1px solid #f0f0f0;
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }
    }
  }

  .social-links {
    @include dir(rtl) {
      margin-left: 10px;
    }

    @include dir(ltr) {
      margin-right: 10px;
    }
  }

  .header-hr {
    border-color: #f1f1f1;
    opacity: 1;
    margin-top: 16px;
  }

  &.scrollBar {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999999999;
    padding: 15px 0;

    &.shadowBox {
      background-color: $color-white;
      box-shadow: 0px 4px 8px 0px #0000001a;
    }
  }

  @include media(768) {
    & {
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
      padding: 12px 0;
    }
  }

  .nav-box {
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 40px;

      li {
        a {
          @include colorPrimary;
          font-size: 16px;
          padding: 10px 5px;
          display: block;

          &:hover {
            color: $color-primary;
            background-color: #f0f9ff;
            border-radius: 10px;
          }
        }
      }
    }
  }
}

@include media(1400) {
  .headerSection {
    .nav-box {
      ul {
        gap: 29px;

        li {
          a {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@include media(1200) {
  .headerSection {
    .nav-box {
      ul {
        gap: 19px;
      }
    }
  }
}

@include media(992) {
  .headerSection {
    .nav-box,
    .profileBox-login,
    .lang-box {
      display: none;
    }

    .social-links {
      display: none !important;
    }

    .header-hr {
      display: none;
    }
  }
}

@media (max-width: 767.98px) {
  .hide-on-sm {
    display: none !important;
  }
}
</style>
